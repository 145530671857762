<template>
    <div>
        <div class="commonInputTopBox">
            <infoHeader
                :content_name="'产品详细信息： ' + infoData.product_name"
            />
            <div style="float: right; margin-top: -65px">
                <el-button type="primary" @click="dialogFormVisibleEdit = true"
                    >编辑产品信息</el-button
                >
                <el-button type="danger" @click="deleteProduct"
                    >删除该产品</el-button
                >
            </div>
        </div>

        <!-- 产品信息 -->
        <el-descriptions
            title="产品信息"
            direction="vertical"
            :column="3"
            border
        >
            <el-descriptions-item label="产品系统ID">{{
                infoData.id
            }}</el-descriptions-item>
            <el-descriptions-item label="产品名">{{
                infoData.product_name
            }}</el-descriptions-item>
            <el-descriptions-item label="产品型号">{{
                infoData.product_series
            }}</el-descriptions-item>
            <el-descriptions-item label="产品类型">{{
                infoData.product_type
            }}</el-descriptions-item>
        </el-descriptions>

        <!-- 切换选项卡 -->
        <el-tabs
            v-model="activeName"
            type="card"
            style="margin-top: 30px"
            @tab-click="handleTabClick"
        >
            <el-tab-pane label="产品资料" name="1">
                <!-- 产品资料 -->
                <el-descriptions title="产品资料，或产品手册，附件">
                    <template slot="extra">
                        <el-button
                            type="primary"
                            size="small"
                            @click="createNewMaterial"
                            >创建新的资料</el-button
                        >
                    </template>
                </el-descriptions>
                <el-table
                    :data="tableData.product_material_list"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="id"
                        label="系统id"
                        width="180"
                    ></el-table-column>
                    <el-table-column
                        prop="material_title"
                        label="资料标题"
                        width="180"
                    ></el-table-column>
                    <el-table-column
                        prop="create_time"
                        label="创建时间"
                    ></el-table-column>
                    <el-table-column
                        prop="update_time"
                        label="最近一次更新时间"
                    ></el-table-column>
                    <el-table-column label="操作" fixed="right" width="180">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                @click="handleEditMaterial(scope.row)"
                                >重命名</el-button
                            >
                            <el-button
                                size="mini"
                                @click="toMaterial(scope.row)"
                                >查看资料</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>

        <!-- 编辑产品信息弹窗 -->
        <el-dialog title="编辑产品信息" :visible.sync="dialogFormVisibleEdit">
            <el-form
                ref="formData"
                :model="formData"
                label-width="160px"
                :label-position="'left'"
            >
                <el-form-item label="产品类目">
                    <el-select
                        v-model="formData.product_type_id"
                        placeholder="请选择"
                        @change="handleTypeChange"
                    >
                        <el-option
                            v-for="item in value"
                            :key="item.id"
                            :label="item.product_type_name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="产品名">
                    <el-input v-model="formData.product_name"></el-input>
                </el-form-item>
                <template v-if="typeData.content_type_code == 1">
                    <el-form-item label="产品型号">
                        <el-input v-model="formData.product_series"></el-input>
                    </el-form-item>
                </template>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleEdit = false"
                    >取消</el-button
                >
                <el-button type="primary" @click="updateProduct"
                    >确定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    product_info,
    product_info_query,
    product_info_create,
    product_delset,
    product_edit,
    product_classify_get,
    product_info_edit
} from '@/api/manage/operation/product/product.js';

export default {
    name: 'productLibraryInfo',
    data() {
        return {
            infoData: {},
            activeName: '1',
            tableData: {
                product_material_list: []
            },
            dialogFormVisibleEdit: false,
            formData: {},
            typeData: {},
            value: []
        };
    },
    created() {
        const id = this.$route.query.id;
        console.log(id);
        this.getProductInfo(id);
        this.getMaterialData(id);
    },
    watch: {
        dialogFormVisibleEdit(newVal) {
            if (newVal) {
                product_classify_get({}).then(res => {
                    if (res.code === 200) {
                        this.value = res.data;
                    } else {
                        this.$message.info(res.msg);
                    }
                });
            }
        }
    },
    methods: {
        getProductInfo(id) {
            product_info({ id }).then(res => {
                this.infoData = res.data;
            });
        },
        updateProduct() {
            const payload = {
                product_id: this.infoData.product_id,
                ...this.formData
            };
            product_edit(payload).then(res => {
                if (res.code === 200) {
                    this.$router.go(0);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        deleteProduct() {
            product_delset({
                product_id: this.infoData.id,
                is_delete: true
            }).then(() => {
                this.$router.go(-1);
            });
        },
        createNewMaterial() {
            this.$prompt('要创建的资料名', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            })
                .then(({ value }) => {
                    product_info_create({
                        product_id: this.infoData.id,
                        material_title: value
                    }).then(() => {
                        this.$router.go(0);
                    });
                })
                .catch(() => {
                    this.$message({ type: 'info', message: '取消输入' });
                });
        },
        getMaterialData(id) {
            console.log(id, '获取资料id');
            product_info_query({ product_id: id }).then(res => {
                this.tableData.product_material_list = res.data;
            });
        },
        handleEditMaterial(item) {
            // handle edit logic here
            this.$prompt('重命名的资料名', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            })
                .then(({ value }) => {
                    console.log(item.id);
                    product_info_edit({
                        product_material_id: item.id,
                        material_title: value
                    }).then(() => {
                        this.$router.go(0);
                    });
                })
                .catch(() => {
                    this.$message({ type: 'info', message: '取消输入' });
                });
        },
        toMaterial(row){
            console.log(row);
            this.$router.push({name:'product_Material',query:row})
        },
        handleTabClick() {
            // handle tab click logic here
        },
        handleTypeChange(selectedItemId) {
            const selectedItem = this.value.find(
                item => item.id === selectedItemId
            );
            this.typeData.content_type_code = selectedItem.content_type_code;
        }
    }
};
</script>

<style scoped></style>
