import request from '@/utils/request'


// 产品库管理接口 -----------------------------------------------------------------

// 一级-所有产品-查询 
export function product_get(data) {
    return request({
        url: '/platform_api/manage_api/product_library/query',
        method: 'post',
        data: data
    })
}

// 一级-产品仓库-添加
export function product_create(data) {
    return request({
        url: '/platform_api/manage_api/product_library/create',
        method: 'post',
        data: data
    })
}

// 一级-产品仓库-编辑
export function product_edit(data) {
    return request({
        url: '/platform_api/manage_api/product_library/update',
        method: 'post',
        data: data
    })
}

// 一级-产品仓库-删除恢复
export function product_delset(data) {
    return request({
        url: '/platform_api/manage_api/product_library/delete',
        method: 'post',
        data: data
    })
}

// 一级-产品详细信息-查询
export function product_info(data) {
    return request({
        url: '/platform_api/manage_api/product_library/info',
        method: 'post',
        data: data
    })
}
// 产品资料管理 ---------------------------------------------------------------------

// 二级-产品库分类-查询
export function product_classify_get(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_type/query',
        method: 'post',
        data: data
    })
}

// 二级-产品库分类-添加
export function product_classify_create(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_type/create',
        method: 'post',
        data: data
    })
}

// 二级-产品库分类-编辑
export function product_classify_edit(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_type/update',
        method: 'post',
        data: data
    })
}

// 二级-产品库分类-删除
export function product_classify_delete(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_type/delete',
        method: 'post',
        data: data
    })
}


// 指定产品资料 ---------------------------------------------------------------------


// 查询
export function product_info_query(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_material/query',
        method: 'post',
        data: data
    })
}

// 创建
export function product_info_create(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_material/create',
        method: 'post',
        data: data
    })
}

// 编辑
export function product_info_edit(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_material/update',
        method: 'post',
        data: data
    })
}

// 删除
export function product_info_delete(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_material/delete',
        method: 'post',
        data: data
    })
}

// 产品库-获取产品硬件列表
export function product_library_equipment_library_query(data) {
    return request({
        url: '/platform_api/manage_api/product_library/equipment_library/query',
        method: 'post',
        data: data
    })
}

// 产品库-创建硬件关联
export function product_library_equipment_library_indexcreate(data) {
    return request({
        url: '/platform_api/manage_api/product_library/equipment_library/create',
        method: 'post',
        data: data
    })
}

// 产品库-删除设备关联
export function product_library_equipment_library_indexdelete(data) {
    return request({
        url: '/platform_api/manage_api/product_library/equipment_library/delete',
        method: 'post',
        data: data
    })
}

// 三级-资料内容-查询
export function product_info_material_get(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_material/content/get',
        method: 'post',
        data: data
    })
}

// 三级-资料内容-更新
export function product_info_material_update(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_material/content/update',
        method: 'post',
        data: data
    })
}



// 三级-资料附件-创造
export function product_info_accessory_create(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_material/accessory/create',
        method: 'post',
        data: data
    })
}

// 三级-资料附件-查找
export function product_info_accessory_get(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_material/accessory/query',
        method: 'post',
        data: data
    })
}
// 三级-资料附件-删除
export function product_info_accessory_delete(data) {
    return request({
        url: '/platform_api/manage_api/product_library/product_material/accessory/delete',
        method: 'post',
        data: data
    })
}


// 产品模块 接口

// 二级-产品模块-查询
export function product_module_query(data) {
    return request({
        url: '/platform_api/manage_api/product_library/abnormal/query',
        method: 'post',
        data: data
    })
}
// 二级-产品模块-添加
export function product_module_create(data) {
    return request({
        url: '/platform_api/manage_api/product_library/abnormal/create',
        method: 'post',
        data: data
    })
}
// 三级-产品模块-异常内容 - 查询
export function product_abnormal_query(data) {
    return request({
        url: '/platform_api/manage_api/product_library/abnormal/error/query',
        method: 'post',
        data: data
    })
}

// 三级-产品模块-异常内容 - 创建
export function product_abnormal_create(data) {
    return request({
        url: '/platform_api/manage_api/product_library/abnormal/error/create',
        method: 'post',
        data: data
    })
}

// 三级-产品模块-异常内容 - 删除
export function product_abnormal_delete(data) {
    return request({
        url: '/platform_api/manage_api/product_library/abnormal/error/del',
        method: 'post',
        data: data
    })
}

// 三级-产品模块-关联设备 - 查询
export function product_module_equipment_query(data) {
    return request({
        url: '/platform_api/manage_api/product_library/abnormal/module_equipment/query',
        method: 'post',
        data: data
    })
}

// 三级-产品模块-关联设备 - 新建
export function product_module_equipment_create(data) {
    return request({
        url: '/platform_api/manage_api/product_library/abnormal/module_equipment/add',
        method: 'post',
        data: data
    })
}

// 三级-产品模块-关联设备 - 删除
export function product_module_equipment_del(data) {
    return request({
        url: '/platform_api/manage_api/product_library/abnormal/module_equipment/delete',
        method: 'post',
        data: data
    })
}